import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'tcweb-material-components/core/button'
import classnames from 'classnames'

const ANIMATION_DEFAULT = {
    animationDuration: '750ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDirection: 'alternate',
    animationIterationCount: 2
}

const useClasses = makeStyles(({ palette, breakpoints }) => ({
    '@keyframes bounce-down': {
        to: {
            transform: 'translateY(-1px)'
        },
        from: {
            transform: 'translateY(0)'
        }
    },
    '@keyframes bounce-up': {
        to: {
            transform: 'translateY(1px)'
        },
        from: {
            transform: 'translateY(0)'
        }
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        borderTop: ({ numItems }) => (numItems > 1 ? `1px solid ${palette.border.primary}` : 'none')
    },
    superToggleWrapper: {
        padding: '0 20px 30px 20px',
        [breakpoints.up('md')]: {
            paddingTop: '50px',
            paddingBottom: '50px'
        }
    },
    superToggle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        fontSize: '1em'
    },
    superTogglerIcons: {
        marginRight: '10px',
        fontSize: '0.7em',
        display: 'flex',
        flexDirection: 'column'
    },
    caretUp: {
        ...ANIMATION_DEFAULT,
        animationName: '$bounce-up'
    },
    caretDown: {
        ...ANIMATION_DEFAULT,
        animationName: '$bounce-down'
    },
    'rotate-90-cw': { transition: 'transform 200ms', transform: 'rotate(90deg)' },
	'rotate-90-ccw': { transition: 'transform 200ms', transform: 'rotate(-90deg);' },
}))

const CollapsibleList = forwardRef(({ className, children, variant = 'multiple', enableExpandAll = true, initialOpen = [], ...rest }, ref) => {
    const { t } = useTranslation()
    const _classes = useClasses({ numItems: React.Children.count(children) })
    const [openList, setOpenList] = useState(initialOpen)
    const expandAll = () => setOpenList(() => React.Children.map(children, (_, index) => index))
    const collapseAll = () => setOpenList([])

    const onClick = (index) => () => {
        const isItemOpen = openList.indexOf(index) > -1

        if (isItemOpen) {
            setOpenList((prevList) => prevList.filter((i) => i !== index))
        } else if (variant === 'multiple') {
            setOpenList((prevList) => [...prevList, index])
        } else {
            setOpenList([index])
        }
    }

    return (
        <div className={className} ref={ref}>
            {enableExpandAll && (
                <div className={_classes.superToggleWrapper}>
                    {openList.length === React.Children.count(children) ? (
                        <Button onClick={collapseAll} className={_classes.superToggle}>
                            <div className={_classes.superTogglerIcons}>
                                <div className={_classes.caretUp}>
                                    <FontAwesomeIcon icon={['tcs', 'arrow-right']} className={classnames(_classes['rotate-90-cw'])} />
                                </div>
                                <div className={_classes.caretDown}>
                                    <FontAwesomeIcon icon={['tcs', 'arrow-right']} className={classnames(_classes['rotate-90-ccw'])} />
                                </div>
                            </div>
                            <span aria-live='polite' aria-relevant='additions text'>
                                {t('Collapse all')}
                            </span>
                        </Button>
                    ) : (
                        <Button onClick={expandAll} className={_classes.superToggle}>
                            <div className={_classes.superTogglerIcons}>
                                <div className={_classes.caretDown}>
                                    <FontAwesomeIcon icon={['tcs', 'arrow-right']} className={classnames(_classes['rotate-90-ccw'])} />
                                </div>
                                <div className={_classes.caretUp}>
                                    <FontAwesomeIcon icon={['tcs', 'arrow-right']} className={classnames(_classes['rotate-90-cw'])} />
                                </div>
                            </div>
                            <span aria-live='polite' aria-relevant='additions text'>
                                {t('Expand all')}
                            </span>
                        </Button>
                    )}
                </div>
            )}
            <ul {...rest} className={_classes.list}>
                {React.Children.map(children, (child, index) => (
                    <li key={`collabsible-item-${index}`}>
                        {React.cloneElement(child, {
                            onClick: onClick(index),
                            open: openList.indexOf(index) > -1
                        })}
                    </li>
                ))}
            </ul>
        </div>
    )
})

export default CollapsibleList
